import React from 'react';
import Layout from 'components/Layout';
import Link from 'components/base/Link';
import classnames from 'classnames';
import IconSearch from 'svg/icn-search';

const Value = ({ pageContext }) => {
  const context = pageContext;
  return (
    <Layout pageTitle="Catalog" className="Value">
      <article className="CollectionTabs container container--thin container--boxed body-copy-only">
        <ul className="block flex">
          <Link
            className="CollectionTabs__search w-100 w-50-md br pointer tc pv2 relative body-subheader"
            to="/collection/search"
            fromTaxon={`${context.name.toLowerCase()}/${context.value.toLowerCase()}`}
            shouldAnimate={false}
          >
            <IconSearch className="CollectionTabs__search-title" />
          </Link>
          <Link
            className="CollectionTabs__index w-100 w-50-md pointer tc pv2 relative body-subheader"
            to="/collection/indexed"
            fromTaxon={`${context.name.toLowerCase()}/${context.value.toLowerCase()}`}
            shouldAnimate={false}
          >
            <span className="CollectionTabs__index-title">Index</span>
          </Link>
        </ul>
      </article>
      <article className="container container--thin container--boxed mt4">
        <section className="block flex relative justify-center align-center pv3 body-copy-only">
          <Link
            to={`/collection/indexed`}
            className="absolute CollectionTaxon__back-link ph3 ph4-xl db gradient-hover bn"
          >
            &lt; Previous
          </Link>
          <h3 className="body-subheader ph3 ph4-xl ttu tc">
            {context.name}: {context.value}
          </h3>
        </section>
        {/* the page header block */}
        <section className="block bt body-copy">
          <ul className="block flex flex-wrap">
            {context.data.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classnames('w-100 w-50-md body-copy brn', {
                    'br-md': index % 2 === 0,
                    bb: index < context.data.length - 1,
                    'bbn-md': index > context.data.length - 3 && index % 2 === 0
                  })}
                >
                  <Link
                    to={`/collection/${item.slug}`}
                    className="ph3 ph4-xl pv2 db gradient-hover bn"
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      </article>
    </Layout>
  );
};

export default Value;
